import React, { useEffect, useState } from 'react'
import './Settings.css'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getPatchUserDetails, getUserUpdate } from '../../Api';
import { getToken } from '../../Helper/Token';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';

const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}

function Settings() {

  const navigate = useNavigate()

  // Get User Details
  const [userDetails, setUserDetails] = useState({})
  const fetchUserDetails = () => {
    getToken();
    axios.patch(getPatchUserDetails)
      .then((res) => {
        setUserDetails(res.data.user)
      })
  }
  useEffect(() => {
    if (localStorage.getItem("token") !== null || sessionStorage.getItem("token") !== null) {
      fetchUserDetails()
    }
  }, [])


  // Edited User Data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    })
  }

  const handleUpdateUser = (e) => {
    e.preventDefault();
    getToken();
    axios.put(`${getUserUpdate}/${userDetails._id}`, userDetails)
      .then((res) => {
        toast.success(res.data.message, toastStyle);
        navigate("/profile")
      })
      .catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  }


  return (
    <>
      <section className="dashboard editpage">
        <div className="dashboard-right-header fr05s-usr-quotsn05s">
          <div className="left-text">
            <h4>Edit Profile</h4>
          </div>
        </div>

        <div className='User-setting-profile-edited'>
          <Form className="user-profile-form">
            <Row className="mb-3">
              <Form.Group as={Col} controlId="name">
                <Form.Label>Your Name</Form.Label>
                <Form.Control className="user-form-fieldd21" placeholder="Your Name" name='name' value={userDetails?.name} onChange={(e) => handleChange(e)} />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control className="user-form-fieldd21" placeholder="Enter Email" name='email' disabled value={userDetails?.email} onChange={(e) => handleChange(e)} />
              </Form.Group>

              <Form.Group as={Col} controlId="phone">
                <Form.Label>Phone No.</Form.Label>
                <Form.Control className="user-form-fieldd21" placeholder='Enter Your Phone no.' name='phone' value={userDetails?.phone} onChange={(e) => handleChange(e)} />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group className="mb-3" controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control className="user-form-fieldd21" as="textarea" rows={3} placeholder="Address" name='Address' value={userDetails?.Address} onChange={(e) => handleChange(e)} />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control className="user-form-fieldd21" type="city" placeholder="Enter City" name='City' value={userDetails?.City} onChange={(e) => handleChange(e)} />
              </Form.Group>

              <Form.Group as={Col} controlId="country">
                <Form.Label>Country</Form.Label>
                <Form.Control className="user-form-fieldd21" type="city" placeholder="Enter Country" name='Country' value={userDetails?.Country} onChange={(e) => handleChange(e)} />
              </Form.Group>

              <Form.Group as={Col} controlId="zipcode">
                <Form.Label>Zip code</Form.Label>
                <Form.Control className="user-form-fieldd21" type="zipcode" placeholder="Enter Zip code" name='ZipCode' value={userDetails?.ZipCode} onChange={(e) => handleChange(e)} />
              </Form.Group>
            </Row>

            <Button variant="primary w-100" className='user-button-on-submit common-btn text-center justify-content-center' type="submit" onClick={(e) => handleUpdateUser(e)}>
              Submit
            </Button>
          </Form>
        </div>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </section>
    </>
  )
}

export default Settings