import React, { useEffect, useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getAdminToken, getToken } from '../../Helper/Token';
import axios from 'axios';
import { getUserFreeCashOrderDetails } from '../../Api';
import { Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { TbSend } from 'react-icons/tb';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}


function ViewPreEventOrderDetails() {
  const navigate = useNavigate();
  const orderId = useParams()

  // Get Order Details
  const [orderDetails, setOrderDetails] = useState()
  const fetchOrderDetails = (e) => {
    getAdminToken();
    axios.get(`${getUserFreeCashOrderDetails}/${orderId.id}`)
      .then((res) => {
        const dateObject = new Date(res.data.orderDetails[0]?.createdAt);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const day = dateObject.getDate();
        const monthIndex = dateObject.getMonth();
        const year = dateObject.getFullYear();
        const hours = dateObject.getHours();
        const minutes = dateObject.getMinutes();
        const amPm = hours >= 12 ? 'PM' : 'AM';
        const hours12 = hours % 12 || 12;

        res.data.orderDetails[0].formattedDateTime = `${day} ${months[monthIndex]}, ${year} ${hours12}:${minutes < 10 ? '0' : ''}${minutes}${amPm}`;

        setOrderDetails(res.data.orderDetails[0])
      })
  }
  useEffect(() => {
    if (orderId?.id) {
      fetchOrderDetails()
    }
  }, [orderId])

  // Download PDF
  const handleDownloadClick = (url) => {
    getAdminToken();
    const link = document.createElement('a');
    link.href = `https://${url}`;
    link.download = `https://${url}`;
    link.setAttribute('target', "_blank");
    link.setAttribute('download', 'Mandli.pdf');
    link.setAttribute('rel', "noreferrer");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after each click
  };

  return (
    <>
      <section className='cont'>

        <div className="dashboard-right-header fr05s-usr-quotsn05s">
          <div className='Go_back_Btn'>
            <NavLink onClick={() => navigate(-1)}><KeyboardBackspaceIcon /></NavLink>
          </div>
          <div className="left-text view_order_user">
            <h4>Order <b>#{orderDetails?.OrderId}</b></h4>
            <p className='m-0'>Here the order details of <b>{orderDetails?.UserInfo.name}</b></p>
          </div>
        </div>

        <div className='orderList'>
          {
            orderDetails ? (
              <div className='order'>
                <div className="order-header new_space">
                  <div className='orderDetails info_teg'>
                    <h1>Order Info</h1>
                  </div>
                </div>

                <div className='info_teg orderDetails blank_space'>
                  <h3>Order No : <span>#{orderDetails?.OrderId}</span></h3>
                  <h3>Booking Date:  <span>{orderDetails?.formattedDateTime}</span></h3>
                  <h3>Purchaser Name :  <span>{orderDetails?.UserInfo.name}</span></h3>
                  <h3>Purchaser Email :  <span>{orderDetails?.UserInfo.email}</span></h3>
                  <h3>Purchaser Phone :  <span>{orderDetails?.UserInfo.phoneNumber}</span></h3>
                  <h3>Payment Type : <span>{orderDetails?.PaymentType === 2 ? "FREE" : orderDetails?.PaymentType === 3 ? "UPI" : "N/A"}</span></h3>
                  <h3>Payment Status :
                    {orderDetails?.PaymentStatus === 1 ? (
                      <span className='Payment_payment'> Pending</span>
                    ) : (
                      <span className='completed_payment'>Completed</span>
                    )}
                  </h3>
                </div>

                <div className="order-header blank_space">
                  <div className='orderDetails info_teg'>
                  </div>
                </div>

                <div className="order-header button_padding-top">
                  <div className='orderDetails info_teg'>
                    <h1>Tickets Info</h1>
                  </div>
                  <div className='Download_PDF_Btn'>
                    {
                      orderDetails.TicketInfo?.map((ticket, i) => {
                        return (
                          <Button className='button_x_download_pdf' key={ticket + 1} startIcon={<DownloadIcon />} onClick={() => handleDownloadClick(orderDetails?.PurchaseticketsData[i].PdfUrl)}>{ticket.TicketTitle}</Button>
                        )
                      })
                    }
                  </div>
                </div>

                <div className='order-body'>
                  <div className="ticket-item">
                    <p>Ticket ID</p>
                    {/* <p>Ticket No.</p> */}
                    <p>Ticket Name</p>
                    <p>Price/Qty</p>
                    <p>Amount</p>
                  </div>
                  {
                    orderDetails.TicketInfo?.map((ticket, i) => {
                      return (<div className="ticket-item">
                        {/* <p>{ticket.TicketId}</p> */}
                        <p>{orderDetails?.PurchaseticketsData[i]?.TicketNo || "N/A"}</p>
                        {/* <p>{ticket.TicketTitle}</p> */}
                        <p>{`${ticket?.TicketTitle === "Season Pass" ? `${"Season Pass : " + ticket.Description}` : `${ticket?.TicketTitle?.split(':')[0] } : ${ticket?.Description}`}`}</p>
                        {/* <p>&#8377;{ticket.Price} &#215; {ticket.PurchasedTickets}</p> */}
                        {/* <p>&#8377;{ticket.Price * ticket.PurchasedTickets}</p> */}
                        <p>&#8377;{ticket.SalePrice && ticket.SalePrice > 0 ? ticket.SalePrice : ticket.Price} &#215; {ticket.PurchasedTickets}</p>
                        <p>&#8377;{(ticket.SalePrice && ticket.SalePrice > 0 ? ticket.SalePrice : ticket.Price) * ticket.PurchasedTickets}</p>
                      </div>)
                    })
                  }
                </div>
                <div className='Order_details_body'>

                </div>
                <div className='total'>
                  <div className='subtotal'>
                    <p>Total item</p>
                    <p>{orderDetails.PurchasedTickets}</p>
                  </div>
                  <div className='grandtotal'>
                    <p>Grand Total</p>
                    <p>&#8377;{orderDetails.OrderTotal}</p>
                  </div>
                </div>
              </div>


            ) : (
              <p>No order details available.</p>
            )
          }

        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </section>
    </>
  )
}

export default ViewPreEventOrderDetails