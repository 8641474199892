import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({ data }) => {
    const [chartData, setChartData] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        let sortedData = data.slice(1).sort((a, b) => {
            if (a[0] === "Season Pass") return 1;
            if (b[0] === "Season Pass") return -1;

            let dayA = parseInt(a[0].replace("Day ", ""));
            let dayB = parseInt(b[0].replace("Day ", ""));

            return dayA - dayB;
        });

        const extractedData = sortedData.map(item => item[1]);
        const extractedCategories = sortedData.map(item => item[0]);

        setChartData(extractedData);
        setCategories(extractedCategories);
    }, [data]);

    console.log(data, chartData);
    const options = {
        chart: {
            type: 'bar',
            height: 430,
        },
        plotOptions: {
            bar: {
                horizontal: window.innerWidth <= 768,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff'],
            },
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
        },
        tooltip: {
            shared: true,
            intersect: false,
        },
        xaxis: {
            categories: categories,
        },
        colors: ['#730000'],
    };

    return (
        <div id="chart">
            <ReactApexChart
                options={options}
                series={[{ data: chartData }]}
                type="bar"
                height={430}
            />
        </div>
    );
};

export default ApexChart;
