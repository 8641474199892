import React, { useEffect, useState } from 'react'
import './AdminSidebar.css'
import { MdOutlineLogout } from 'react-icons/md'
import { NavLink, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { getAdminRoutes, getAdminToken } from '../../../Helper/Token'
import axios from 'axios'
import { getAdminDetails } from '../../../Api'
import { BsChevronDown } from 'react-icons/bs'


function AdminSidebar({ children, closeMenu }) {

    const navigate = useNavigate();
    const location = useLocation();

    // Logout function: clears storage and navigates to login
    const handleLogOut = (e) => {
        e.preventDefault();
        localStorage.clear();
        sessionStorage.clear();
        navigate("/admin/login");
    };

    // State for admin details
    const [adminDetails, setAdminDetails] = useState({});

    const fetchAdminDetails = async () => {
        try {
            getAdminToken(); // Ensure token is available
            const response = await axios.patch(getAdminDetails);
            setAdminDetails(response.data.admin);
        } catch (error) {
            console.error("Error fetching admin details:", error);
        }
    };

    useEffect(() => {
        fetchAdminDetails();
    }, []);

    // State for accessible routes
    const [finalRoutes, setFinalRoutes] = useState([]);

    useEffect(() => {
        let data = [];

        // Check for admin access in both localStorage and sessionStorage
        if (localStorage.getItem("adminAccess")) {
            data = JSON.parse(localStorage.getItem("adminAccess"));
        } else if (sessionStorage.getItem("adminAccess")) {
            data = JSON.parse(sessionStorage.getItem("adminAccess"));
        }

        // Make a deep copy of routes to avoid direct mutation
        let allRoutes = [...getAdminRoutes].map((route) => {
            // Check if the route has child routes
            if (route.hasChild) {
                const childPathMatch = route.child.some((child) => child.path === location.pathname);

                return {
                    ...route,
                    isOpen: route.path === location.pathname || childPathMatch
                };
            }

            return route;
        });

        // If access data is available, filter routes based on access
        if (data.length > 0) {
            const accessData = allRoutes.filter((route) => {
                const parentHasAccess = data.some((accessItem) => accessItem.path === route.path);

                const childHasAccess = route.hasChild && route.child.some((childRoute) =>
                    data.some((accessItem) => accessItem.path === childRoute.path)
                );

                return parentHasAccess || childHasAccess;
            });

            setFinalRoutes(accessData);
        }
    }, [getAdminRoutes, location.pathname]);

    // Handle sidebar button click (for closing the menu)
    const toggleSubMenu = (e, path) => {
        e.preventDefault();
        e.stopPropagation();

        let updatedRoutes = finalRoutes.map((route) => {
            if (route.path === path) {
                return {
                    ...route,
                    isOpen: !route.isOpen
                };
            }
            return route;
        });

        // Update the state with the new array
        setFinalRoutes(updatedRoutes);
    };



    return (
        <div className='Admin_SideBar'>
            <div className="container flexted-body">

                <div className="usersidebar ">
                    <div className='Admin_Info'>
                        <h1>{adminDetails?.name}</h1>
                        <span>{adminDetails?.email}</span>
                    </div>
                    {finalRoutes?.map((route) => (
                        <>
                            {route.hasChild ?
                                <NavLink className={`userlink has-child ${route.path === location.pathname ? "current" : ""} ${route.isOpen ? 'active submenu-active' : ''}`} to={route.path} key={route.id} id={route.id} onClick={(e) => toggleSubMenu(e, route.path)} >
                                    <div className='name'>
                                        <div className="ussericon">{route.icons}</div>
                                        <div className="user-link_text">{route.name}</div>
                                    </div>
                                    <div className={`user-link_icons ${route.isOpen ? 'active submenu-active' : ''}`} >
                                        <BsChevronDown />
                                    </div>
                                </NavLink> :
                                <NavLink className={`userlink ${route.path === location.pathname ? "current" : ""}`} to={route.path} key={route.id} id={route.id} >
                                    <div className="ussericon">{route.icons}</div>
                                    <div className="user-link_text">{route.name}</div>
                                </NavLink>
                            }
                            {route.hasChild && (
                                <ul className={`submenu submenu-entered ${route.isOpen ? 'active submenu-active' : ''}`}>
                                    {
                                        route.child.map((child) => {
                                            return <li key={child.key}>
                                                <NavLink className={`userlink Dropdown_menus_ad  ${child.path === location.pathname ? "current" : ""}`} to={child.path} onClick={closeMenu}>
                                                    <div className="ussericon">{child.icons}</div>
                                                    <div className="user-link_text">{child.name}</div>
                                                </NavLink>
                                            </li>
                                        })
                                    }
                                </ul>
                            )}
                        </>
                    ))}

                    <NavLink to="/logout" key="logout" className="userlink" id="logout" onClick={(e) => handleLogOut(e)} >
                        <div className="ussericon"><MdOutlineLogout /></div>
                        <div className="user-link_text">Log Out</div>
                    </NavLink>
                </div>
                <main className='main-wrap-user' > <div className='userdashboardcnt'>{children}</div> </main>
            </div>
        </div>
    )
}

export default AdminSidebar

