import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getAdminToken } from '../../Helper/Token';
import { getOrderList, getTicketScanUpdates } from '../../Api';
import { Box, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { formatNumberWithCommas } from '../../Helper/validation';
import { SuperBalls } from '@uiball/loaders';

function TicketsScanStatus() {
    const [rows, setRows] = useState([]);

    const columns = [
        { field: 'id', headerName: 'Sr. No.', width: 70 },
        { field: 'day', headerName: 'Day', width: 150, },
        { field: 'scanPending', headerName: 'Tickets Not Used', width: 150, },
        { field: 'scanned', headerName: 'Tickets Used', width: 150, },
        { field: 'totalTickets', headerName: 'Total Tickets', width: 150, },
    ];

    const fetchTicketScanUpdates = () => {
        getAdminToken();
        axios.get(getTicketScanUpdates)
            .then((res) => {
                res.data.result.map((ele, i) => {
                    ele.id = i + 1;
                    ele.totalTickets = Number(ele.scanPending) + Number(ele.scanned);
                })

                res.data.result.sort((a, b) => {
                    // Handle "Season Pass" separately to always be last
                    if (a.day === "Season Pass") return 1;
                    if (b.day === "Season Pass") return -1;

                    // Extract the number part from "Day X"
                    const dayA = parseInt(a.day.replace("Day ", ""));
                    const dayB = parseInt(b.day.replace("Day ", ""));

                    // Compare the day numbers
                    return dayA - dayB;
                });

                setRows(res.data.result)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    console.log(rows);
    useEffect(() => {
        fetchTicketScanUpdates();
    }, [])

    return (
        <>
            <section className='adminDashboard'>
                <div className="dashboard-right-header fr05s-usr-quotsn05s">
                    <div className="left-text">
                        <h4>Tickets Scan Status</h4>
                        <p className="m-0">Here is each day tickets scan status.</p>
                    </div>
                </div>

                <div className="ticketsContainer">
                    <Box sx={{ width: '100%' }}>
                        <DataGrid
                            autoHeight {...rows}
                            rows={rows}
                            columns={columns}
                            disableSelectionOnClick
                            pagination={false}
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        <SuperBalls size={45} speed={1.4} color="var(--primary)" />
                                    </Stack>
                                )
                            }}
                        />
                    </Box>
                </div>
            </section>
        </>
    );
}
export default TicketsScanStatus