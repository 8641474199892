import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { CreateCashOrder, getOngoingEvent, getTicketsList } from '../../Api'
import axios from 'axios'
import { calculatePriceBreakdown, getAdminToken, getToken } from '../../Helper/Token'
import { useNavigate } from 'react-router-dom'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { Col, Modal, Pagination, Row, Switch, Text, useModal } from '@nextui-org/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateRequiredFields } from '../../Helper/validation'

const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}


function CreateOnGoingEventOrders() {
  const navigate = useNavigate()
  const { setVisible: setContentEditModalVisible, bindings: addContentModel } = useModal();

  const openAdd = () => setContentEditModalVisible(true)
  const closeAdd = () => setContentEditModalVisible(false)

  // Variables
  const [tickets, setTickets] = useState([]);
  const [selected, setSelected] = useState({});
  const [purchaseData, setPurchaseData] = useState({
    selectedTickets: [],
    purchaseTicketsData: [],
    grandTotal: 0,
    totalQty: 0
  });

  // Get details from localstorage
  useEffect(() => {
    const purchaseDataFromStorage = localStorage.getItem('purchaseTicketsData');
    if (purchaseDataFromStorage) {
      const parsedData = JSON.parse(purchaseDataFromStorage);
      setPurchaseData({
        ...purchaseData,
        selectedTickets: parsedData.selectedTickets,
        purchaseTicketsData: parsedData.purchaseTicketsData,
        grandTotal: parsedData.grandTotal,
        totalQty: parsedData.totalQty
      });
      const result = parsedData.selectedTickets.reduce((accumulator, currentObject) => {
        const id = currentObject.id;
        const quantity = parseInt(currentObject.quantity, 10);

        if (id && !isNaN(quantity)) {
          accumulator[id] = quantity;
        }

        return accumulator;
      }, {});
      setSelected(result)
    }
  }, []);

  // Get All Tickets List
  const fetchTickets = () => {
    getToken();
    axios.get(`${getTicketsList}`)
      .then((res) => {
        if (res.data.result.docs) {
          res.data.result.docs.forEach((ele) => {
            if (!ele.isCombo && ele.TicketTitle) {
              const data = []
              const parts = ele.TicketTitle.split(':');
              const ticketDay = parts[0]?.trim();
              const ticketName = parts[1]?.trim();
              const ticketNameData = {
                ticketDay,
                ticketName,
              };
              data.push(ticketNameData)
              ele.ticketNameData = data
            }
          });
          setTickets(res.data.result.docs);
        }
      })
      .catch((error) => {
        console.error("Error fetching tickets:", error);
      });
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  // Handle Quantity Increase Decrease
  const handleQuantityChange = (_id, quantity, e) => {
    e.preventDefault();

    setSelected((prevselected) => {
      const updatedselected = {
        ...prevselected,
        [_id]: parseInt(quantity, 10) >= 0 ? parseInt(quantity, 10) : 0,
      };

      if (updatedselected[_id] === 0) {
        delete updatedselected[_id];
      }

      return updatedselected;
    });
  };

  // Calculate the grand grand total and Tickets
  useEffect(() => {
    let totalQty = 0;
    let grandTotal = 0;
    const selectedTickets = [];

    for (const id in selected) {
      const quantity = selected[id];
      const ticket = tickets.find((t) => t._id === id);

      if (ticket) {
        totalQty += quantity;
        const ticketPrice = (ticket.SalePrice && ticket.SalePrice > 0) ? ticket.SalePrice : ticket.Price;
        const amount = ticketPrice * quantity;
        grandTotal += amount;

        selectedTickets.push({
          id: ticket._id,
          quantity: quantity
        });
      }
    }

    const purchaseTicketsData = selectedTickets.map((ticket) => ({
      _id: ticket.id,
      TicketTitle: tickets.find((t) => t._id === ticket.id)?.isCombo ? tickets.find((t) => t._id === ticket.id)?.TicketTitle : tickets.find((t) => t._id === ticket.id)?.ticketNameData || '',
      // TicketTitle: tickets.find((t) => t._id === ticket.id).Description || '',
      Description: tickets.find((t) => t._id === ticket.id)?.Description || '',
      // Price: tickets.find((t) => t._id === ticket.id)?.Price || 0,
      Price: tickets.find((t) => t._id === ticket.id)?.SalePrice > 0 ? tickets.find((t) => t._id === ticket.id)?.SalePrice : tickets.find((t) => t._id === ticket.id).Price || 0,
      isCombo: tickets.find((t) => t._id === ticket.id)?.isCombo || false,
      quantity: parseInt(ticket.quantity, 10),
      // Amount: tickets.find((t) => t._id === ticket.id)?.Price * parseInt(ticket.quantity, 10) || 0,
      Amount: (tickets.find((t) => t._id === ticket.id)?.SalePrice > 0 ? tickets.find((t) => t._id === ticket.id)?.SalePrice : tickets.find((t) => t._id === ticket.id).Price) * parseInt(ticket.quantity, 10) || 0,
      PhaseName: tickets.find((t) => t._id === ticket.id)?.PhaseData[0].PhaseName || ""
    }));

    setPurchaseData({
      ...purchaseData,
      totalQty,
      grandTotal,
      selectedTickets,
      purchaseTicketsData
    });
  }, [selected, tickets]);

  // Proceed to Checkout
  const handleProceed = (e) => {
    e.preventDefault();

    if (purchaseData.selectedTickets.length > 0) {
      openAdd()
    }
  }


  // Create Cash order 
  const [newData, setNewData] = useState({
    name: "",
    email: "",
    phone: "",
    PaymentType: ''
  })
  const handleChange = (e, data) => {
    const { name, value } = e.target;
    setNewData({
      ...newData,
      [name]: value
    })
    if (data === 'payment') {
      setNewData({
        ...newData,
        [name]: parseInt(value),
      })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setNewData({
        ...newData,
        ticketId: purchaseData.selectedTickets
      })
    }, 500)
  }, [purchaseData.selectedTickets])

  // Check Errors
  const [errorData, setErrorData] = useState({});
  const [errShow, setErrShow] = useState(false);
  useEffect(() => {
    const error = validateRequiredFields(newData, ["PaymentType", "ticketId"]);
    setErrorData(error);
  }, [newData]);



  // Loading 
  const [loading, setIsLoading] = useState(false)

  // Place Order
  const handleCreateContent = (e) => {
    setIsLoading(true)
    e.preventDefault();
    getAdminToken();
    setErrShow(true)
    if (Object.keys(errorData).length === 0) {
      axios.post(getOngoingEvent, newData)
        .then((res) => {
          closeAdd();
          setPurchaseData({
            selectedTickets: [],
            purchaseTicketsData: [],
            grandTotal: 0,
            totalQty: 0
          })
          setTimeout(() => {
            setIsLoading(false)
          }, 500);
          setNewData({
            ...newData,
            name: '', email: "", phone: "", PaymentType: ''
          })
          setErrShow(false)
          setSelected({})
          toast.success(res.data.message, toastStyle);
        })
        .catch((err) => {
          toast.error(err.response.data.message, toastStyle);
        })
    } else {
      setTimeout(() => {
        setIsLoading(false)
      }, 500);
    }
  }


  return (
    <>
      <section className='Cash_tickets'>
        <div className="dashboard-right-header fr05s-usr-quotsn05s">
          <div className="left-text">
            <h4>Create On Going Event Orders </h4>
          </div>
        </div>

        <div className="main">
          <div className="container admin-create-order sold_tickets_containber cash_tickets">
            <div className='ticketBookingBlock'>
              <div className='ticketsBlock cash_block'>
                {tickets.map((ticket) => (
                  <div
                    className={
                      selected[ticket._id] > 0 && ticket.isCombo
                        ? 'ticket combo selected'
                        : ticket.isCombo
                          ? 'ticket combo'
                          : selected[ticket._id] > 0
                            ? 'ticket selected'
                            : 'ticket'
                    }
                    key={ticket._id}>
                    <div className='border'></div>
                    <div className='ticketData'>
                      <div className='name'>
                        <h2>{ticket.isCombo ? ticket.TicketTitle : ticket.Description} {ticket.isCombo ? <span>{ticket.Description}</span> : ''}</h2>
                      </div>
                      <div className='booking'>
                        <div className="price">
                          <h3>
                            {ticket.SalePrice && ticket.SalePrice > 0 ? (
                              <>
                                <span className='strikePrice' style={{ textDecoration: 'line-through', marginRight: '10px' }}>
                                  &#8377;{ticket.Price}
                                </span>
                                &#8377;{ticket.SalePrice}
                              </>
                            ) : (
                              <>&#8377;{ticket.Price}</>
                            )}
                            <span>({ticket.PhaseData[0].PhaseName})</span>
                          </h3>
                        </div>
                        <div className='quantity'>
                          <div className='action'>
                            <button className='qty-btn minus' onClick={(e) => handleQuantityChange(ticket._id, (selected[ticket._id] || 0) - 1, e)} > <BiMinus /> </button>
                            <span className='qty'>{selected[ticket._id] || 0}</span>
                            <button className='qty-btn plus' onClick={(e) => handleQuantityChange(ticket._id, (selected[ticket._id] || 0) + 1, e)} > <BiPlus /> </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='summary cash_order_summary'>
                <div className='total'>
                  <h2 className={purchaseData.totalQty > 0 ? "" : "empty"}>Qty : <span>{purchaseData.totalQty}</span></h2>
                  <h2 className={purchaseData.totalQty > 0 ? "" : "empty"}>Total : <span>&#8377; {purchaseData.grandTotal}</span></h2>
                </div>
                <div className='checkout'>
                  <Button className='common-btn justify-content-center' disabled={purchaseData.totalQty < 1} onClick={(e) => handleProceed(e)}>Proceed to Checkout</Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal scroll width="1000px" preventClose aria-labelledby="modal-title" aria-describedby="modal-description" className='model_body_cash' {...addContentModel} >
          <Modal.Header justify='start' style={{ background: 'var(--primary)' }}>
            <Text id="modal-title" size={18} style={{ color: '#fff', fontSize: '25px' }}>
              Place On Going Event Order
            </Text>
          </Modal.Header>
          <Modal.Body>

            <div className='cash_order_details'>
              <div className='order_details'>
                <h4 className='pb-4' style={{ color: 'var(--primary)', fontWeight: '600', fontFamily: 'Futura Md BT' }}>Tickets Summary</h4>
                <div className='order-body'>
                  <div className="ticket-item">
                    <p>Ticket Name</p>
                    <p>Price/Qty</p>
                    <p>Amount</p>
                  </div>
                  {
                    purchaseData?.purchaseTicketsData?.map((ticket) => {
                      return (<div className="ticket-item">
                        {/* <p>{ticket.isCombo ? `${ticket.TicketTitle}` : `${ticket.TicketTitle[0]?.ticketDay}:${ticket.TicketTitle[0]?.ticketName}`}</p> */}
                        <p>{`${ticket.TicketTitle[0]?.ticketDay} : ${ticket.Description}`}</p>
                        <p>&#8377;{ticket.Price} &#215; {ticket.quantity}</p>
                        <p>&#8377;{ticket.Price * ticket.quantity}</p>
                      </div>)
                    })
                  }
                </div>
                <div className='total'>
                  <div className='subtotal'>
                    <p>Total item</p>
                    <p>{purchaseData.totalQty}</p>
                  </div>
                  <div className='grandtotal'>
                    <p>Grand Total</p>
                    <p>&#8377;{purchaseData.grandTotal}</p>
                  </div>
                </div>
              </div>
              <div className='order_data'>
                <h4 className='pb-4' style={{ color: '#000', fontSize: '18px', fontFamily: 'Futura Md BT', margin: '0px' }}>Contact Information</h4>
                <Row className="row mb-3 gap-3">
                  <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
                    <Form.Label>Name</Form.Label>
                    <Form.Control className='w-100' type="text" placeholder="Enter Name" name="name" onChange={(e) => handleChange(e)} />
                    {errShow && <p className='error-msg'>{errorData.name?.message}</p>}
                  </Form.Group>
                </Row>

                <Row className="row mb-3 gap-3">
                  <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
                    <Form.Label>Email</Form.Label>
                    <Form.Control className='w-100' type="email" placeholder="Enter Email" name="email" onChange={(e) => handleChange(e)} />
                    {errShow && <p className='error-msg'>{errorData.email?.message}</p>}
                  </Form.Group>
                </Row>

                <Row className="row mb-3 gap-3">
                  <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control className='w-100' type="number" placeholder="Enter Phone Number" name="phone" onChange={(e) => handleChange(e)} />
                    {errShow && <p className='error-msg'>{errorData.phone?.message}</p>}
                  </Form.Group>
                </Row>

                <Row className="row mb-3 gap-0">
                  <Form.Label>Payment Mode</Form.Label>
                  <div className='paymentType'>
                    <Form.Check key="FREE" type="radio" id={`phase-radio-free`} label="FREE" value={2} name='PaymentType' checked={newData.PaymentType === 2} onChange={(e) => handleChange(e, 'payment')} />
                    <Form.Check key="UPI" type="radio" id={`phase-radio-upi`} label="UPI" value={3} name='PaymentType' checked={newData.PaymentType === 3} onChange={(e) => handleChange(e, 'payment')} />
                  </div>
                  {errShow && <p className='error-msg'>{errorData.PaymentType?.message}</p>}
                </Row>
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer className='model_footer_btns'>
            <Button auto flat className='modelClose m-0' onClick={() => closeAdd()}>
              Cancel
            </Button>
            <Button auto className='modelSubmit common-btn m-0 p-2' onClick={(e) => handleCreateContent(e)} disabled={loading} >
              Place Order
            </Button>
          </Modal.Footer>
        </Modal>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

      </section>
    </>
  )
}

export default CreateOnGoingEventOrders