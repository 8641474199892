import React, { useEffect, useState } from 'react'
import { getDashboardStats, getOngoingEvent, getPhaseList, } from '../../Api'
import axios from 'axios'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { getAdminToken } from '../../Helper/Token'
import { BsTicketPerforated } from 'react-icons/bs'
import { Form } from 'react-bootstrap'
import { formatNumberWithCommas } from '../../Helper/validation'
import ApexBarChart from './ApexBarChart';

function OnGoingStats() {

    const [totalVisitors, setTotalVisitors] = useState(0)
    const [stats, setStates] = useState({
        totalTickets: 0,
        total24HSale: 0,
        totalSale: 0
    })

    const fetchOrderList = () => {
        getAdminToken();

        axios.get(`${getOngoingEvent}`)
            .then((res) => {
                setStates({
                    ...stats,
                    totalTickets: res.data.result.totalDocs,
                    totalSale: res.data.result.GrandTotal,
                    total24HSale: res.data.result.last24HourSaleAmount
                })
            })
    }
    useEffect(() => {
        fetchOrderList();
    }, [])

    // Phase Options
    const [allPhase, setAllPhase] = useState([])
    const fetchAllPhase = () => {
        axios.get(getPhaseList)
            .then((res) => {
                res.data.result.map((ele) => {
                    ele.value = ele._id;
                    ele.label = ele.PhaseName
                })
                setAllPhase(res.data.result)
            })
    }
    useEffect(() => {
        fetchAllPhase();
    }, [])

    // Get Selected Phase
    const [phaseValue, setPhaseValue] = useState("");
    const handlePhase = (e) => {
        setPhaseValue(e.target.value)
    }


    // Get Dashboard Stats
    const [onGoingStats, setOnGoingStats] = useState([]);

    // Get Dashboard Stats
    const fetchDashboardonGoingStats = () => {
        const apiURL = `${getDashboardStats}/Ongoing?Eventinfo=2&phaseId=${phaseValue}`;

        axios.get(apiURL)
            .then((res) => {
                const newData = [];
                let cumulativeTotal = 0;
                for (let i = 0; i < res.data.result.length; i++) {
                    const { day, totalCashPurchasedTickets, totalUpiPurchasedTickets, totalPurchasedTickets } = res.data.result[i];
                    if (day === "Season Pass") {
                        cumulativeTotal += (totalPurchasedTickets * 10);
                    } else {
                        cumulativeTotal += totalPurchasedTickets;
                    }

                    newData.push([day, totalCashPurchasedTickets, totalUpiPurchasedTickets]);
                }
                setTotalVisitors(cumulativeTotal)
                setOnGoingStats(newData);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        fetchDashboardonGoingStats();
    }, [phaseValue]);

    // get Todays Sale
    const [todaysSale, setTodaysSale] = useState(0)
    const fetchTodatSAle = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const nextDay = new Date(today.getTime() + (24 * 60 * 60 * 1000));

        axios.get(`${getOngoingEvent}?dateStart=${today.toISOString()}&dateEnd=${nextDay.toISOString()}`)
            .then((res) => {
                axios.get(`${getOngoingEvent}?dateStart=${today.toISOString()}&dateEnd=${nextDay.toISOString()}&limit=${res.data.result.totalDocs}`)
                    .then((resp) => {
                        let totalSaleToday = 0;
                        resp.data.result.docs.map((ele) => {
                            totalSaleToday += ele.OrderTotal
                        })
                        setTodaysSale(totalSaleToday)
                    })
            })
    }
    useEffect(() => {
        fetchTodatSAle()
    }, [])

    return (
        <div>
            <div className="userdash-row">
                <div className="dashboard-stats-box ">
                    <div className="single-feature-box sfb01">
                        <div className="single-feature-icon">
                            <BsTicketPerforated />
                        </div>
                        <div className="single-feature-data">
                            <h6>{stats.totalTickets || 0}</h6>
                            <p>Total Orders</p>
                        </div>
                    </div>
                </div>
                <div className="dashboard-stats-box ">
                    <div className="single-feature-box sfb01">
                        <div className="single-feature-icon">
                            <BsTicketPerforated />
                        </div>
                        <div className="single-feature-data">
                            <h6>{totalVisitors || 0}</h6>
                            <p>Total Visitors</p>
                        </div>
                    </div>
                </div>
                <div className="dashboard-stats-box">
                    <div className="single-feature-box bg-danger-50 ">
                        <div className="single-feature-icon">
                            <CurrencyRupeeIcon />
                        </div>
                        <div className="single-feature-data">
                            <h6>{formatNumberWithCommas(stats.totalSale) || 0}</h6>
                            <p>Total Sale</p>
                        </div>
                    </div>
                </div>
                <div className="dashboard-stats-box">
                    <div className="single-feature-box ">
                        <div className="single-feature-icon">
                            <CurrencyRupeeIcon />
                        </div>
                        <div className="single-feature-data ">
                            <h6>{formatNumberWithCommas(todaysSale) || 0}</h6>
                            <p>Today's Sale</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard-subheading dashboard">
                <div className="left-text">
                    <h5>Each Day Pass</h5>
                </div>
            </div>
            <div>
                <div className='phaseList'>
                    <Form.Check
                        key="all"
                        type="radio"
                        id={`onEvent-radio`}
                        label="All"
                        value=""
                        checked={phaseValue === ""}
                        onChange={(e) => handlePhase(e)}
                    />
                    {allPhase.map((ele) => (
                        <Form.Check
                            key={ele._id}
                            type="radio"
                            id={`onEvent-radio-${ele._id}`}
                            label={ele.PhaseName}
                            value={ele._id}
                            checked={phaseValue === ele._id}
                            onChange={(e) => handlePhase(e)}
                        />
                    ))}
                </div>
                <ApexBarChart data={onGoingStats} />
            </div>
        </div>
    )
}

export default OnGoingStats