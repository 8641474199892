import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'
import { FormControlLabel, IconButton } from '@material-ui/core';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { getAdminDetails, getDashboardStats, getMembersList, getOngoingEvent, getPhaseList, getPreEventOrderList, getProcessedorderslist, getTicketsListAdmin, getdownloadCsv } from '../../Api'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { BsTicketPerforated } from 'react-icons/bs'
import 'react-toastify/dist/ReactToastify.css';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { getAdminToken } from '../../Helper/Token';
import { BiExport, BiSad } from 'react-icons/bi'
import { CiSearch } from 'react-icons/ci'
import Form from 'react-bootstrap/Form';
import { Modal, Pagination, Text, useModal } from '@nextui-org/react'
import FIlterIcon from '../../Images/Mandli/Filter.png'
import { DateRangePicker } from 'react-date-range';
import { addDays, startOfDay } from 'date-fns';
import { formatNumberWithCommas } from '../../Helper/validation';
import { Col, Row } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import ApexBarChart from '../Dashboard/ApexBarChart';
import { Stack } from '@mui/material';
import { SuperBalls } from '@uiball/loaders';

const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}

function PreEventOrder() {
  // Print Data
  const columns = [
    { field: 'srno', headerName: 'Id', width: 40 },
    { field: 'UserName', headerName: 'User Name', width: 130, },
    { field: 'UserEmail', headerName: 'Email', width: 130, },
    { field: 'ISTDate', headerName: 'Date', width: 200, },
    { field: 'totalTickets', headerName: 'Total Tickets', width: 100, },
    { field: 'PurchasedTickets', headerName: 'Total Visitors', width: 100, },
    { field: 'PaymentType', headerName: 'Payment Type', width: 110, },
    { field: 'OrderTotal', headerName: 'Total', width: 80, },
    {
      field: "actions", headerName: "Action", sortable: false, width: 75,
      renderCell: (prevents) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <MatView index={prevents.row} />
          </div>
        );
      }
    }
  ];

  // Get Order List
  const [rows, setRows] = useState([]);
  const [stats, setStates] = useState({
    totalTickets: 0,
    total24HSale: 0,
    totalSale: 0
  })
  const [todaysSale, setTodaysSale] = useState(0)


  // Filter Popup
  const { setVisible: setFilterPopupVisible, bindings: filterPopupBindings } = useModal();
  const handleFilterOpen = () => {
    setFilterPopupVisible(true)
  }
  const handleFilterClose = () => {
    setFilterPopupVisible(false)
  }

  // All Filtration 
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    const startDate = new Date(state[0]?.startDate).toISOString();
    setDateStart(startDate);

    const endDate = new Date(state[0]?.endDate).toISOString();
    setDateEnd(endDate);
  }, [state]);

  const [filter, setFilter] = useState({
    paymentType: "",
    dateStart: "",
    dateEnd: "",
    PaymentStatus: "",
    memberId: "",
    phase: "",
    Tickets: "",
    sortBy: ""
  })
  const [dateStart, setDateStart] = useState("")
  const [dateEnd, setDateEnd] = useState("")
  const [phase, setphase] = useState([])
  const [selectedPhase, setSelectedPhase] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [Tickets, setTickets] = useState([])
  const [totalVisitors, setTotalVisitors] = useState(0)
  const [data, setData] = useState([]);

  const MatView = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <NavLink className="view_user_order" to={`/admin/on-going-event-orders/view-order/${index._id}`}>
            <IconButton aria-label="add an alarm" className='dyFlextIcon'>
              <VisibilityOutlinedIcon />
            </IconButton>
          </NavLink>
        }
      />
    );
  };

  const [allPhase, setAllPhase] = useState([])
  const fetchAllPhase = () => {
    axios.get(getPhaseList)
      .then((res) => {
        res.data.result.map((ele) => {
          ele.value = ele._id;
          ele.label = ele.PhaseName
        })
        setAllPhase(res.data.result)
      })
  }
  useEffect(() => {
    fetchAllPhase();
  }, [])

  // Get Selected Phase
  const [phaseValue, setPhaseValue] = useState("");
  const handlePhase = (e) => {
    setPhaseValue(e.target.value)
  }

  // Get Dashboard Stats
  const fetchDashboardOnlineStats = () => {
    const apiURL = `${getDashboardStats}/Ongoing?Eventinfo=2&phaseId=${phaseValue}`;

    axios.get(apiURL)
      .then((res) => {
        const newData = [];
        let cumulativeTotal = 0;
        for (let i = 0; i < res.data.result.length; i++) {
          const { day, totalCashPurchasedTickets, totalUpiPurchasedTickets, totalPurchasedTickets } = res.data.result[i];
          if (day === "Season Pass") {
            cumulativeTotal += (totalPurchasedTickets * 10);
          } else {
            cumulativeTotal += totalPurchasedTickets;
          }

          newData.push([day, totalCashPurchasedTickets, totalUpiPurchasedTickets]);
        }
        setTotalVisitors(cumulativeTotal)
        setData(newData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchDashboardOnlineStats();
  }, [phaseValue]);


  // Pagination
  const [pageState, setPageState] = useState({
    total: 0,
    totalPages: 0,
    page: 1,
    pageSize: 10
  })
  const handlePageChange = (selectedPage) => {
    setPageState((prevState) => ({ ...prevState, page: selectedPage }));
  };
  const ResultInfo = () => {
    const start = (pageState.page - 1) * pageState.pageSize + 1;
    const end = Math.min(start + pageState.pageSize - 1, pageState.total);

    return (
      <div className="result-info">
        Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Orders
      </div>
    );
  };
  const handlePageSizeChange = (newPageSize) => {
    setPageState((prevState) => ({
      ...prevState,
      page: 1,
      pageSize: newPageSize,
    }));
  };


  // Search
  const [search, setSearch] = useState("")
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  // Get Phase List
  const fetchphase = () => {
    getAdminToken();
    axios.get(getPhaseList)
      .then((res) => {
        setphase(res.data.result)
      })
  }
  const handlePhaseChnage = (e) => {
    setSelectedPhase(e.target.value)
  }

  const handleSortByChange = (e) => {
    setSortBy(e.target.value)
  }

  // get Selected Tickets
  const fetchTicketsList = () => {
    getAdminToken();
    axios.get(getTicketsListAdmin)
      .then((res) => {
        axios.get(`${getTicketsListAdmin}?limit=${res.data.result.totalDocs}`)
          .then((resp) => {
            setTickets(resp.data.result.docs)
          })
      })
  }

  // Get selected Tickets
  const [ticketFilters, setTicketFilters] = useState([]);
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setTicketFilters((prevFilters) => [...prevFilters, value]);
    } else {
      setTicketFilters((prevFilters) => prevFilters.filter((ticket) => ticket !== value));
    }
  };

  // Handle Date Range Filte
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === 'start') {
      setStartDate(value)
    } else if (name === "end") {
      setEndDate(value)
    }
  }

  // Handle Apply Filter
  const handleFilter = (e) => {
    e.preventDefault();
    fetchOrderList();
    handleFilterClose();
    setFilter({
      ...filter,
      Tickets: ticketFilters.join(","),
      sortBy: sortBy,
      phase: selectedPhase,
      dateStart: startDate,
      dateEnd: endDate,
    })
  }

  // Handle Reset Filter
  const handleResetFilter = (e) => {
    e.preventDefault();
    fetchOrderList();
    handleFilterClose();
    setFilter({
      paymentType: "",
      dateStart: "",
      dateEnd: "",
      PaymentStatus: "",
      memberId: "",
      phase: "",
      Tickets: "",
      sortBy: ""
    });
    setTicketFilters([])
    setSelectedPhase('')
    setSortBy('')
    setEndDate('')
    setStartDate('')
  };

  const fetchOrderList = () => {
    getAdminToken();
    let apiUrl = `${getOngoingEvent}?page=${pageState.page}&limit=${pageState.pageSize}`
    if (search) { apiUrl += `&search=${search}`; }
    if (filter.sortBy) { apiUrl += `&sortBy=${filter.sortBy}`; }
    if (filter.phase) { apiUrl += `&phase=${filter.phase}`; }
    if (filter.Tickets) { apiUrl += `&Tickets=${filter.Tickets}`; }
    if (filter.dateStart) { apiUrl += `&dateStart=${new Date(filter.dateStart).toISOString()}`; }
    if (filter.dateEnd) { apiUrl += `&dateEnd=${new Date(filter.dateEnd).toISOString()}`; }
    if (filter.paymentType) { apiUrl += `&paymentType=${filter.paymentType}`; }
    if (filter.memberId) { apiUrl += `&memberId=${filter.memberId}`; }

    axios.get(`${apiUrl}`)
      .then((res) => {
        res.data.result.docs.map((ele, i) => {
          const json = res.data.result;
          setPageState((prevState) => ({ ...prevState, total: json.totalDocs, totalPages: json.totalPages }));

          ele.srno = (res.data.result.page * pageState.pageSize) + (i + 1) - pageState.pageSize
          ele.id = i + 1;

          ele.UserName = ele.UserInfo?.name || "N/A"
          ele.UserEmail = ele.UserInfo?.email || "N/A"
          const timestamp = ele.createdAt;
          const date = new Date(timestamp);
          ele.ISTDate = date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" });

          if (ele.PaymentType.PaymenttypeNumber == 1) {
            ele.PaymentType = "Online"
          } else if (ele.PaymentType == 2) {
            ele.PaymentType = "FREE"
          } else if (ele.PaymentType == 3) {
            ele.PaymentType = "UPI"
          }

          ele.totalTickets = ele?.TicketInfo?.length
        });
        setRows(res.data.result.docs)
        if (res.data.result.page === 1) {
          setStates({
            ...stats,
            totalSale: res.data.result.GrandTotal || 0,
            totalTickets: res.data.result.totalDocs || 0,
            total24HSale: res.data.result.last24HourSaleAmount || 0
          })
        } else {
          setStates({
            ...stats,
            totalTickets: res.data.result.totalDocs || 0,
            total24HSale: res.data.result.last24HourSaleAmount || 0
          })
        }
      })
  }
  useEffect(() => {
    fetchOrderList();
    fetchphase();
    fetchTicketsList();
  }, [search, pageState.page, pageState.pageSize, filter])

  const fetchTodatSAle = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const nextDay = new Date(today.getTime() + (24 * 60 * 60 * 1000));

    axios.get(`${getOngoingEvent}?dateStart=${today.toISOString()}&dateEnd=${nextDay.toISOString()}`)
      .then((res) => {
        axios.get(`${getOngoingEvent}?dateStart=${today.toISOString()}&dateEnd=${nextDay.toISOString()}&limit=${res.data.result.totalDocs ? res.data.result.totalDocs : ""}`)
          .then((resp) => {
            let totalSaleToday = 0;
            resp.data.result.docs.map((ele) => {
              totalSaleToday += ele.OrderTotal
            })
            setTodaysSale(totalSaleToday)
          })
      })
  }
  useEffect(() => {
    fetchTodatSAle()
  }, [])

  // Download CSV List
  const backendUrl = 'http://';
  const [Export, setExport] = useState([])

  const HandleDownloadCsv = async (Export) => {
    getAdminToken();
    axios.get(getdownloadCsv).then((res) => {
      toast.success(res.data.message, toastStyle);
      setExport(res.data.result)
      const link = document.createElement('a');
      link.href = `${backendUrl}${res.data.result}`;
      link.download = `${backendUrl}${res.data.result}`;
      link.setAttribute('target', "_blank")
      link.setAttribute('download', 'Mandli.pdf')
      link.setAttribute('rel', "noreferrer")
      document.body.appendChild(link);
      link.click();
    }).catch((err) => {
      toast.error(err.response.data.message, toastStyle);
    })
  }


  return (
    <div className='userdashboard'>
      <div className="dashboard-right-header">
        <div className="left-text">
          <h4>On Going Event Orders</h4>
        </div>
        <div className="export_sheet createOrderBtn">
          <NavLink to="/admin/on-going-event-orders/create-order">
            <Button className='usdercmntbtn common-btn' ><AddIcon />Add Order</Button>
          </NavLink>
        </div>
      </div>

      <div className="userdash-row mb-4">
        <div className="dashboard-stats-box ">
          <div className="single-feature-box sfb01">
            <div className="single-feature-icon">
              <LocalActivityIcon />
            </div>
            <div className="single-feature-data">
              <h6>{stats.totalTickets || 0}</h6>
              <p>Total Orders</p>
            </div>
          </div>
        </div>
        <div className="dashboard-stats-box ">
          <div className="single-feature-box sfb01">
            <div className="single-feature-icon">
              <BsTicketPerforated />
            </div>
            <div className="single-feature-data">
              <h6>{totalVisitors || 0}</h6>
              <p>Total Visitors</p>
            </div>
          </div>
        </div>
        <div className="dashboard-stats-box">
          <div className="single-feature-box bg-danger-50 ">
            <div className="single-feature-icon">
              <CurrencyRupeeIcon />
            </div>
            <div className="single-feature-data">
              <h6>{formatNumberWithCommas(stats.totalSale) || 0}</h6>
              <p>Total Sale</p>
            </div>
          </div>
        </div>
        <div className="dashboard-stats-box">
          <div className="single-feature-box ">
            <div className="single-feature-icon">
              <CurrencyRupeeIcon />
            </div>
            <div className="single-feature-data ">
              <h6>{formatNumberWithCommas(todaysSale) || 0}</h6>
              <p>Today's Sale</p>
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard-subheading drh02qat">
        <div className="left-text">
          <h5>Each Day Pass</h5>
        </div>
      </div>
      <div>
        <div className='phaseList'>
          <Form.Check
            key="all"
            type="radio"
            id={`phase-radio`}
            label="All"
            value=""
            checked={phaseValue === ""}
            onChange={(e) => handlePhase(e)}
          />
          {allPhase.map((ele) => (
            <Form.Check
              key={ele._id}
              type="radio"
              id={`phase-radio-${ele._id}`}
              label={ele.PhaseName}
              value={ele._id}
              checked={phaseValue === ele._id}
              onChange={(e) => handlePhase(e)}
            />
          ))}
        </div>
        <ApexBarChart data={data} />
      </div>

      <div className='filter-Search userList sold_tickets'>
        <div className="search-box form-item position-relative x_sold_tickets">
          <Form.Control type="text" placeholder="Search.." name="seach" className="search-input w-100" onChange={(e) => handleSearch(e)} />
          <CiSearch />
        </div>
        <div className='filter-block'>
          <div className='qutrowftr08s'>
            <div className='create-block'>
              <img src={FIlterIcon} alt="" variant="contained" onClick={handleFilterOpen} />
            </div>
          </div>
        </div>

        <div className='filter-block'>
          <div className='qutrowftr08s'>
            <div className='create-block export_sheet'>
              <Button className='usdercmntbtn common-btn' onClick={HandleDownloadCsv} ><BiExport />EXPORT</Button>
            </div>
          </div>
        </div>
      </div>

      <div className='datagridtableusqot00qat'>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            autoHeight {...rows}
            rows={rows}
            columns={columns}
            pageSize={pageState.pageSize}
            disableSelectionOnClick
            pagination={false}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <SuperBalls size={45} speed={1.4} color="var(--primary)" />
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <BiSad />
                </Stack>
              )
            }}
          />
        </Box>
      </div>
      <div className='pagination-block'>
        <ResultInfo />
        <div className='customPagination'>
          <div className="page-size-select">
            Page Size:{" "}
            <select
              value={pageState.pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <Pagination
            loop
            color="success"
            total={pageState.totalPages}
            initialPage={1}
            boundaries={1}
            siblings={0}
            onChange={(page) => handlePageChange(page)}
            currentPage={pageState.page}
          />
        </div>
      </div>

      {/* Filter Popup */}
      <Modal scroll width="700px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...filterPopupBindings} >
        <Modal.Header justify='start'>
          <Text id="modal-title" size={18}>
            <b>Filter</b>
          </Text>
        </Modal.Header>
        <Modal.Body>
          <div className='orderFilter'>
            <div className="short_buy_buttons mb-4">
              <div className="colone">
                <h1>Sort By Phases</h1>
                <div className='checkBoxList'>
                  <Form.Check key="all" type="radio" id={`phase-radio-btn`} label="All" value="" checked={selectedPhase === ""} onChange={(e) => handlePhaseChnage(e)} />
                  {phase.map((ele) => (
                    <Form.Check key={ele._id} type="radio" id={`phase-radio-btn-${ele._id}`} label={ele.PhaseName} value={ele._id} checked={selectedPhase === ele._id} onChange={(e) => handlePhaseChnage(e)} />
                  ))}
                </div>
              </div>
            </div>
            <div className="short_buy_buttons mb-4">
              <div className="colone">
                <h1>Sort By Price</h1>
                <div className='checkBoxList'>
                  <Form.Check key="all" type="radio" id={`sortBy-radio-btn`} label="Default" value="" checked={sortBy === ""} onChange={(e) => handleSortByChange(e)} />
                  <Form.Check key="all" type="radio" id={`sortBy-radio-btn-1`} label="High to Low" value="1" checked={sortBy === "1"} onChange={(e) => handleSortByChange(e)} />
                  <Form.Check key="all" type="radio" id={`sortBy-radio-btn-2`} label="Low to High" value="2" checked={sortBy === "2"} onChange={(e) => handleSortByChange(e)} />
                </div>
              </div>
            </div>
            <div className="datePiker">
              <Form>
                <Row className="mandli-row mb-3 gap-3">
                  <Form.Group as={Col} controlId="form-control-product">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control type="datetime-local" placeholder="Start Sale Date" value={startDate} name="start" onChange={(e) => handleChange(e)} />
                  </Form.Group>

                  <Form.Group as={Col} controlId="form-control-product">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control type="datetime-local" placeholder="End Sale Date" value={endDate} name="end" onChange={(e) => handleChange(e)} />
                  </Form.Group>
                </Row>
              </Form>
              {/* <DateRangePicker
                onChange={(e) => handleDateState(e)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={state}
                direction="horizontal"
                className='w-100'
              /> */}
            </div>
            <div className='staut_checked_Tickets mb-4'>
              <div className=" cst_form_title">
                <h1>Ticket</h1>
              </div>
              <Row className="mb-3 gap-3">
                <Form.Group className="access-row w-100">
                  {
                    Tickets.map((ele, i) => {
                      return (
                        <Form.Check type="checkbox" id={ele._id} key={ele._id}
                          name={ele.TicketTitle}
                          value={ele.TicketTitle === "Day 1: Ekam" || ele.TicketTitle === "Day 1: Ekam " ? "Day 1: Ekam ,Day 1: Ekam" : ele.TicketTitle}
                          label={`${ele.TicketTitle} (${ele.PhaseData[0].PhaseName})`}
                          checked={ticketFilters.includes(ele.TicketTitle)}
                          onChange={(e) => handleChecked(e)}
                        />
                      )
                    })
                  }
                </Form.Group>
              </Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat className='modelClose' onClick={(e) => handleResetFilter(e)}>
            Reset
          </Button>
          <Button auto className='modelSubmit common-btn' onClick={(e) => handleFilter(e)}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default PreEventOrder