import React from 'react'
import SiteFooter from '../Components/Footer/SiteFooter'
import UserSidebar from '../Components/UserPanel/UserSidebar/UserSidebar'
import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react'
import Header from '../Components/Header/Header'
import { getToken } from '../Helper/Token';
import { getPatchUserDetails } from '../Api';
import axios from 'axios';

function UserLayout({ page }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token") == null && sessionStorage.getItem("token") == null) {
      navigate("/login")
    }
  }, [])

  // Get User Details
  const fetchUserDetails = () => {
    getToken();
    axios.patch(getPatchUserDetails)
      .then((res) => {
        if (res.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          navigate('/login')
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          navigate('/login')
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null || sessionStorage.getItem("token") !== null) {
      fetchUserDetails();
    }
  }, []);


  return (
    <>
      <div className="main-wrapper wrapper-main-cnt-userbodycnt">
        <div className="userbodycnt-Layout">
          <Header />
          <UserSidebar>
            {page}
          </UserSidebar>
          <SiteFooter />
        </div>
      </div>
    </>
  )
}

export default UserLayout