import React, { useEffect, useState } from 'react'
import '../../Pages/BuyNow/BuyNow.css'
import './CashOrder.css'
import { Button, Form } from 'react-bootstrap'
import { CreateCashOrder, getTicketsList } from '../../Api'
import axios from 'axios'
import { calculatePriceBreakdown, getAdminToken, getToken } from '../../Helper/Token'
import { useNavigate } from 'react-router-dom'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { Col, Modal, Pagination, Row, Switch, Text, useModal } from '@nextui-org/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const allTickets = [
    {
        "_id": "64f2193428c128803fb60eac",
        "TicketTitle": "season pass",
        "isCombo": true,
        "TicketType": "64e4c10a6236f9b55b36e1de",
        "Description": "15th October to 23th October",
        "Startsale": "2023-09-01T00:00:00.000Z",
        "Endsale": "2023-09-20T12:10:33.860Z",
        "Price": 9000,
        "Capacity": null,
        "SKU": "",
        "flag": 1,
        "createdAt": "2023-09-01T17:02:44.165Z",
        "updatedAt": "2023-09-13T10:05:48.650Z",
        "PhaseData": [
            {
                "PhaseName": "PHASE 1"
            }
        ]
    },
    {
        "_id": "64e5ca1e5c2e9dafe47bdf6c",
        "TicketTitle": "Day 1: Ekam",
        "TicketType": "64e4c10a6236f9b55b36e1de",
        "Description": "15th October",
        "Startsale": "2023-08-25T00:00:00.000Z",
        "Endsale": "2023-08-23T12:10:33.860Z",
        "Price": 1500,
        "Capacity": null,
        "SKU": "",
        "flag": 1,
        "createdAt": "2023-08-23T08:58:06.652Z",
        "updatedAt": "2023-09-12T17:10:23.530Z",
        "PhaseData": [
            {
                "PhaseName": "PHASE 1"
            }
        ],
        "ticketNameData": [
            {
                "ticketDay": "Day 1",
                "ticketName": "Ekam"
            }
        ]
    },
    {
        "_id": "64e5ca2c5c2e9dafe47bdf70",
        "TicketTitle": "Day 2: Beej",
        "TicketType": "64e4c10a6236f9b55b36e1de",
        "Description": "16th October",
        "Startsale": "2023-08-25T00:00:00.000Z",
        "Endsale": "2023-08-30T00:00:00.000Z",
        "Price": 1500,
        "Capacity": null,
        "SKU": "",
        "flag": 1,
        "createdAt": "2023-08-23T08:58:20.244Z",
        "updatedAt": "2023-09-12T12:57:37.441Z",
        "PhaseData": [
            {
                "PhaseName": "PHASE 1"
            }
        ],
        "ticketNameData": [
            {
                "ticketDay": "Day 2",
                "ticketName": "Beej"
            }
        ]
    },
    {
        "_id": "64e5ca465c2e9dafe47bdf76",
        "TicketTitle": "Day 3: Teej",
        "TicketType": "64e4c10a6236f9b55b36e1de",
        "Description": "17th October",
        "Startsale": "2023-08-25T00:00:00.000Z",
        "Endsale": "2023-08-30T00:00:00.000Z",
        "Price": 1500,
        "Capacity": null,
        "SKU": "",
        "flag": 1,
        "createdAt": "2023-08-23T08:58:46.966Z",
        "updatedAt": "2023-09-11T12:46:38.763Z",
        "PhaseData": [
            {
                "PhaseName": "PHASE 1"
            }
        ],
        "ticketNameData": [
            {
                "ticketDay": "Day 3",
                "ticketName": "Teej"
            }
        ]
    },
    {
        "_id": "64e5eea7feac14c16ec6424e",
        "TicketTitle": "Day 4: Chauth",
        "TicketType": "64e4c10a6236f9b55b36e1de",
        "Description": "18th October",
        "Startsale": "2023-08-23T11:24:53.976Z",
        "Endsale": "2026-10-23T21:11:33.976Z",
        "Price": 1500,
        "Capacity": null,
        "SKU": "",
        "flag": 1,
        "createdAt": "2023-08-23T11:33:59.323Z",
        "updatedAt": "2023-09-10T12:32:49.308Z",
        "PhaseData": [
            {
                "PhaseName": "PHASE 1"
            }
        ],
        "ticketNameData": [
            {
                "ticketDay": "Day 4",
                "ticketName": "Chauth"
            }
        ]
    },
    {
        "_id": "64e5edb8324c0584f59d6027",
        "TicketTitle": "Day 5: Pacham",
        "TicketType": "64e4c10a6236f9b55b36e1de",
        "Description": "19th October",
        "Startsale": "2023-08-23T11:24:53.976Z",
        "Endsale": "2026-10-23T21:11:33.976Z",
        "Price": 1500,
        "Capacity": null,
        "SKU": "",
        "flag": 1,
        "createdAt": "2023-08-23T11:30:00.997Z",
        "updatedAt": "2023-09-12T12:54:41.455Z",
        "PhaseData": [
            {
                "PhaseName": "PHASE 1"
            }
        ],
        "ticketNameData": [
            {
                "ticketDay": "Day 5",
                "ticketName": "Pacham"
            }
        ]
    },
    {
        "_id": "6500617482e1647e8b9405ba",
        "TicketTitle": "Day 6: Chhath",
        "TicketType": "64e4c10a6236f9b55b36e1de",
        "Description": "20th October",
        "Startsale": "2023-09-12T13:01:00.000Z",
        "Endsale": "2023-10-08T13:01:00.000Z",
        "Price": 1500,
        "Capacity": 5000,
        "SKU": "",
        "flag": 1,
        "createdAt": "2023-09-12T13:02:44.874Z",
        "updatedAt": "2023-09-12T13:02:44.874Z",
        "PhaseData": [
            {
                "PhaseName": "PHASE 1"
            }
        ],
        "ticketNameData": [
            {
                "ticketDay": "Day 6",
                "ticketName": "Chhath"
            }
        ]
    },
    {
        "_id": "64f088f22850118a00b58b91",
        "TicketTitle": "Day 7: Satam",
        "TicketType": "64e4c10a6236f9b55b36e1de",
        "Description": "21st October ",
        "Startsale": "2023-07-29T12:31:00.000Z",
        "Endsale": "2023-08-01T12:28:00.000Z",
        "Price": 1500,
        "Capacity": 3455,
        "SKU": "diqued",
        "flag": 1,
        "createdAt": "2023-08-31T12:34:58.449Z",
        "updatedAt": "2023-09-11T12:46:33.376Z",
        "PhaseData": [
            {
                "PhaseName": "PHASE 1"
            }
        ],
        "ticketNameData": [
            {
                "ticketDay": "Day 7",
                "ticketName": "Satam"
            }
        ]
    },
    {
        "_id": "64e5ef2ec04e886c55dcf2c9",
        "TicketTitle": "Day 8: Atham",
        "TicketType": "64e4c10a6236f9b55b36e1de",
        "Description": "22nd October",
        "Startsale": "2023-08-23T11:34:45.970Z",
        "Endsale": "2026-10-23T21:11:33.976Z",
        "Price": 1500,
        "Capacity": null,
        "SKU": "",
        "flag": 1,
        "createdAt": "2023-08-23T11:36:14.161Z",
        "updatedAt": "2023-09-10T12:32:17.274Z",
        "PhaseData": [
            {
                "PhaseName": "PHASE 1"
            }
        ],
        "ticketNameData": [
            {
                "ticketDay": "Day 8",
                "ticketName": "Atham"
            }
        ]
    },
    {
        "_id": "650061bc82e1647e8b9405c2",
        "TicketTitle": "day 9: Naum",
        "TicketType": "64e4c10a6236f9b55b36e1de",
        "Description": "23rd October",
        "Startsale": "2023-09-12T13:03:00.000Z",
        "Endsale": "2023-10-08T13:03:00.000Z",
        "Price": 1500,
        "Capacity": 5600,
        "SKU": "",
        "flag": 1,
        "createdAt": "2023-09-12T13:03:56.222Z",
        "updatedAt": "2023-09-12T13:03:56.222Z",
        "PhaseData": [
            {
                "PhaseName": "PHASE 1"
            }
        ],
        "ticketNameData": [
            {
                "ticketDay": "day 9",
                "ticketName": "Naum"
            }
        ]
    }
]

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
}


function CashOrder() {
    const navigate = useNavigate()
    const { setVisible: setContentEditModalVisible, bindings: addContentModel } = useModal();

    const openAdd = () => setContentEditModalVisible(true)
    const closeAdd = () => setContentEditModalVisible(false)

    // Variables
    const [tickets, setTickets] = useState(allTickets);
    const [selected, setSelected] = useState({});
    const [purchaseData, setPurchaseData] = useState({
        selectedTickets: [],
        purchaseTicketsData: [],
        grandTotal: 0,
        totalQty: 0
    });

    // Get details from localstorage
    useEffect(() => {
        const purchaseDataFromStorage = localStorage.getItem('purchaseTicketsData');
        if (purchaseDataFromStorage) {
            const parsedData = JSON.parse(purchaseDataFromStorage);
            setPurchaseData({
                ...purchaseData,
                selectedTickets: parsedData.selectedTickets,
                purchaseTicketsData: parsedData.purchaseTicketsData,
                grandTotal: parsedData.grandTotal,
                totalQty: parsedData.totalQty
            });
            const result = parsedData.selectedTickets.reduce((accumulator, currentObject) => {
                const id = currentObject.id;
                const quantity = parseInt(currentObject.quantity, 10);

                if (id && !isNaN(quantity)) {
                    accumulator[id] = quantity;
                }

                return accumulator;
            }, {});
            setSelected(result)
        }
    }, []);

    // Get All Tickets List
    const fetchTickets = () => {
        getToken();
        axios.get(`${getTicketsList}`)
            .then((res) => {
                if (res.data.result.docs) {
                    res.data.result.docs.forEach((ele) => {
                        if (!ele.isCombo && ele.TicketTitle) {
                            const data = []
                            const parts = ele.TicketTitle.split(':');
                            const ticketDay = parts[0]?.trim();
                            const ticketName = parts[1]?.trim();
                            const ticketNameData = {
                                ticketDay,
                                ticketName,
                            };
                            data.push(ticketNameData)
                            ele.ticketNameData = data
                        }
                    });
                    setTickets(res.data.result.docs);
                }
            })
            .catch((error) => {
                console.error("Error fetching tickets:", error);
            });
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    // Handle Quantity Increase Decrease
    const handleQuantityChange = (_id, quantity, e) => {
        e.preventDefault();

        setSelected((prevselected) => {
            const updatedselected = {
                ...prevselected,
                [_id]: parseInt(quantity, 10) >= 0 ? parseInt(quantity, 10) : 0,
            };

            if (updatedselected[_id] === 0) {
                delete updatedselected[_id];
            }

            return updatedselected;
        });
    };

    // Calculate the grand grand total and Tickets
    useEffect(() => {
        let totalQty = 0;
        let grandTotal = 0;
        const selectedTickets = [];

        for (const id in selected) {
            const quantity = selected[id];
            const ticket = tickets.find((t) => t._id === id);

            if (ticket) {
                totalQty += quantity;
                const amount = ticket.Price * quantity;
                grandTotal += amount;

                selectedTickets.push({
                    id: ticket._id,
                    quantity: quantity.toString().padStart(2, '0')
                });
            }
        }

        const purchaseTicketsData = selectedTickets.map((ticket) => ({
            _id: ticket.id,
            TicketTitle: tickets.find((t) => t._id === ticket.id)?.isCombo ? tickets.find((t) => t._id === ticket.id)?.TicketTitle : tickets.find((t) => t._id === ticket.id)?.ticketNameData || '',
            Description: tickets.find((t) => t._id === ticket.id)?.Description || '',
            Price: tickets.find((t) => t._id === ticket.id)?.Price || 0,
            isCombo: tickets.find((t) => t._id === ticket.id)?.isCombo || false,
            quantity: parseInt(ticket.quantity, 10),
            Amount: tickets.find((t) => t._id === ticket.id)?.Price * parseInt(ticket.quantity, 10) || 0,
            PhaseName: tickets.find((t) => t._id === ticket.id)?.PhaseData[0].PhaseName || ""
        }));

        setPurchaseData({
            ...purchaseData,
            totalQty,
            grandTotal,
            selectedTickets,
            purchaseTicketsData
        });
    }, [selected, tickets]);

    // Proceed to Checkout
    const handleProceed = (e) => {
        e.preventDefault();

        if (purchaseData.selectedTickets.length > 0) {
            openAdd()
        }
    }


    // Create Cash order 
    const [newData, setNewData] = useState({
        name: "",
        email: "",
        phone: ""
    })
    const handleChange = (e, data) => {
        const { name, value } = e.target;
        setNewData({
            ...newData,
            [name]: value,
            ticketId: purchaseData.selectedTickets
        })
    }
    const handleCreateContent = (e) => {
        e.preventDefault();
        getAdminToken();
        axios.post(CreateCashOrder, newData)
            .then((res) => {
                closeAdd();
                setPurchaseData({
                    selectedTickets: [],
                    purchaseTicketsData: [],
                    grandTotal: 0,
                    totalQty: 0
                })
                setSelected({})
                toast.success(res.data.message, toastStyle);
            })
            .catch((err) => {
                toast.error(err.response.data.message, toastStyle);
            })
    }

    return (
        <>
            <section className='Cash_tickets'>
                <div className="dashboard-right-header fr05s-usr-quotsn05s">
                    <div className="left-text">
                        <h4>Create Pre Event Orders </h4>
                    </div>
                </div>

                <div className="main">
                    <div className="container sold_tickets_containber cash_tickets">
                        <div className='ticketBookingBlock'>
                            <div className='ticketsBlock cash_block'>
                                {tickets.map((ticket) => (
                                    <div
                                        className={
                                            selected[ticket._id] > 0 && ticket.isCombo
                                                ? 'ticket combo selected'
                                                : ticket.isCombo
                                                    ? 'ticket combo'
                                                    : selected[ticket._id] > 0
                                                        ? 'ticket selected'
                                                        : 'ticket'
                                        }
                                        key={ticket._id}>
                                        <div className='border'></div>
                                        <div className='dayName'>
                                            <h3>{ticket.isCombo ? "all 10 days" : ticket.ticketNameData[0].ticketDay}</h3>
                                        </div>
                                        <div className='ticketData'>
                                            <div className='name'>
                                                <h2>{ticket.isCombo ? ticket.TicketTitle : ticket.ticketNameData[0].ticketName} <span>{ticket.Description}</span></h2>
                                            </div>
                                            <div className='booking'>
                                                <div className="price">
                                                    <h3>&#8377;{ticket.Price}</h3>
                                                    {/* <p>(Price incl. of 18% GST)</p> */}
                                                </div>
                                                <div className='quantity'>
                                                    <div className='action'>
                                                        <button className='qty-btn minus' onClick={(e) => handleQuantityChange(ticket._id, (selected[ticket._id] || 0) - 1, e)} > <BiMinus /> </button>
                                                        <span className='qty'>{selected[ticket._id] || 0}</span>
                                                        <button className='qty-btn plus' onClick={(e) => handleQuantityChange(ticket._id, (selected[ticket._id] || 0) + 1, e)} > <BiPlus /> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='summary cash_order_summary'>
                                <div className='total'>
                                    <h2 className={purchaseData.totalQty > 0 ? "" : "empty"}>Qty : <span>{purchaseData.totalQty}</span></h2>
                                    <h2 className={purchaseData.totalQty > 0 ? "" : "empty"}>Total : <span>&#8377; {purchaseData.grandTotal}</span></h2>
                                </div>
                                <div className='checkout'>
                                    <Button className='common-btn justify-content-center' disabled={purchaseData.totalQty < 1} onClick={(e) => handleProceed(e)}>Proceed to Checkout</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal scroll width="1000px"  aria-labelledby="modal-title" aria-describedby="modal-description" className='model_body_cash' {...addContentModel} >
                    <Modal.Header justify='start' style={{background: 'var(--primary)'}}>
                        <Text id="modal-title" size={18} style={{color: '#fff', fontSize: '25px'}}>
                            Create Cash Order
                        </Text>
                    </Modal.Header>
                    <Modal.Body>

                        <div className='cash_order_details'>
                            <div className='order_details'>
                                <h4 className='pb-4' style={{ color: 'var(--primary)', fontWeight: '600',  fontFamily: 'Futura Md BT'}}>Tickets Summary</h4>
                                <div className='order-body'>
                                    <div className="ticket-item">
                                        <p>Ticket Name</p>
                                        <p>Price/Qty</p>
                                        <p>Amount</p>
                                    </div>
                                    {
                                        purchaseData?.purchaseTicketsData?.map((ticket) => {
                                            return (<div className="ticket-item">
                                                <p>{ticket.isCombo ? `${ticket.TicketTitle}` : `${ticket.TicketTitle[0]?.ticketDay}:${ticket.TicketTitle[0]?.ticketName}`}</p>
                                                <p>&#8377;{ticket.Price} &#215; {ticket.quantity}</p>
                                                <p>&#8377;{ticket.Price * ticket.quantity}</p>
                                            </div>)
                                        })
                                    }
                                </div>
                                <div className='total'>
                                    <div className='subtotal'>
                                        <p>Total item</p>
                                        <p>{purchaseData.totalQty}</p>
                                    </div>
                                    <div className='grandtotal'>
                                        <p>Grand Total</p>
                                        <p>&#8377;{purchaseData.grandTotal}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='order_data'>
                                <h4 className='pb-4' style={{ color: '#000', fontSize: '18px',fontFamily: 'Futura Md BT', margin: '0px'}}>Contact Information</h4>
                                <Row className="row mb-3 gap-3">
                                    <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control className='w-100' type="text" placeholder="Enter Name" name="name" onChange={(e) => handleChange(e)} />
                                    </Form.Group>
                                </Row>

                                <Row className="row mb-3 gap-3">
                                    <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control className='w-100' type="email" placeholder="Enter Email" name="email" onChange={(e) => handleChange(e)} />
                                    </Form.Group>
                                </Row>

                                <Row className="row mb-3 gap-3">
                                    <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control className='w-100' type="number" placeholder="Enter Phone Number" name="phone" onChange={(e) => handleChange(e)} />
                                    </Form.Group>
                                </Row>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className='model_footer_btns'>
                        <Button auto flat className='modelClose m-0' onClick={() => closeAdd()}>
                            Cancel
                        </Button>
                        <Button auto className='modelSubmit common-btn m-0 p-2' onClick={(e) => handleCreateContent(e)} >
                            Create Cash Order
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

            </section>
        </>
    )
}

export default CashOrder