import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Home2024 from "./Pages/Home/Home2024";
import Login from "./Pages/Login/Login";
import Signup from "./Pages/Signup/Signup";
import ForgotPass from "./Pages/ForgotPass/ForgotPass";
import AdminLogin from "./Admin/AdminLogin/AdminLogin";
import { useEffect, useState } from "react";
import AdminDashboard from "./Admin/Dashboard/Dashboard";
import Settings from "./User/Settings/Settings";
import Profile from "./User/Profile/Profile";
import AdminLayout from "./Layouts/AdminLayout";
import PublicLayout from "./Layouts/PublicLayout";
import UserLayout from "./Layouts/UserLayout";
import ForgotPassword from "./Admin/ForgotPassword/ForgotPassword";
import ContactUs from "./Pages/ContactUs/ContactUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsandConditions from "./Pages/TermsandConditions/TermsandConditions";
import { getAdminRoutes, getFinalRoutes, getToken } from "./Helper/Token";
import AddMember from "./Admin/Members/Members";
import OrderTable from "./Admin/Orders/Orders";
import UserTable from "./Admin/Users/Users";
import OrderView from "./Admin/ViewOrder/ViewOrder";
import TicketsManagment from "./Admin/Tickets/Tickets";
import Dashboard from "./User/Dashboard/Dashboard";
import Orders from "./User/Orders/Orders";
import RefundAndCancellation from "./Pages/RefundAndCancellation/RefundAndCancellation";
import BuyNow from "./Pages/BuyNow/BuyNow";
import Checkout from "./Pages/Checkout/Checkout";
import Phases from "./Admin/Phases/Phases";
import SoldTickets from "./Admin/SoldTickets/SoldTickets";
import AdminSupport from "./Admin/Support/Support";
import CMS from "./Admin/CMS/CMS";
import UserOrders from "./Admin/UserOrders/UserOrders";
import ViewOrder from "./User/ViewOrder/ViewOrder";
import CheckoutLayout from "./Layouts/CheckoutLayout";
import About from "./Pages/About/About";
import Gallery from "./Pages/Gallery/Gallery";
import CommingSoon from "./Pages/CommingSoon/CommingSoon";
import Error from "./Pages/Error/Error";
import axios from "axios";
import { getWebMaintenance } from "./Api";
import Maintenance from "./Pages/Maintenance/Maintenance";
import CashOrder from "./Admin/CashOrder/CashOrder";
import UnProcessedOrder from "./Admin/Orders/UnProcessedOrder";
import ViewTickets from "./Admin/Users/ViewTickets";
import ThankYou from "./Components/Thankyou/ThankYou";
import PaymentFailed from "./Components/PaymentFailed/PaymentFailed";
import PreEventTickets from "./Admin/PreEventTickets/PreEventOrder";
import PreEventOrder from "./Admin/PreEventTickets/PreEventOrder";
import CreatePreEventOrder from "./Admin/PreEventTickets/CreatePreEventOrder";
import OnGoingEventOrders from "./Admin/OnGoingEventOrders/OnGoingEventOrders";
import CreateOnGoingEventOrders from "./Admin/OnGoingEventOrders/CreateOnGoingEventOrders";
import StudentOrders from "./Admin/StudentOrders/StudentOrders";
import CreateStudentOrders from "./Admin/StudentOrders/CreateStudentOrders";
import ViewPreEventOrderDetails from "./Admin/PreEventTickets/ViewPreEventOrderDetails";
import EachDaySale from "./Admin/Dashboard/EachDaySale";
import ViewOnGoingEventOrderDetails from "./Admin/OnGoingEventOrders/ViewOnGoingEventOrderDetails";
import MemberOrder from "./Admin/Members/MemberOrder";
import AppMemberOrder from "./Admin/Members/AppMemberOrder";
import element from "./Images/Mandli2024/desktopBglayer.svg"
import TicketsScanStatus from "./Admin/Dashboard/TicketsScanStatus";


function App() {
  const routesConfig = [
    // { path: '/', element: <CommingSoon /> },
    { path: '/', element: <PublicLayout page={<Home2024 />} /> },
    // { path: '/home2', element: <PublicLayout page={<Home2024 />} /> },
    { path: '/about', element: <PublicLayout page={<About />} /> },
    { path: '/gallery', element: <PublicLayout page={<Gallery />} /> },
    { path: '/orderstatus', element: <CheckoutLayout page={<ThankYou />} /> },
    // { path: '/thankyou', element: <CheckoutLayout page={<ThankYou />} /> },
    // { path: '/paymentcancel', element: <CheckoutLayout page={<PaymentFailed />} /> },
    // User routes
    { path: '/dashboard', element: <UserLayout page={<Dashboard />} /> },
    { path: '/orders', element: <UserLayout page={<Orders />} /> },
    { path: '/orders/vieworder/:id', element: <UserLayout page={<ViewOrder />} /> },
    { path: '/profile', element: <UserLayout page={<Profile />} /> },
    { path: '/profile/setting', element: <UserLayout page={<Settings />} /> },
    // Public routes
    { path: '/buy-now', element: <PublicLayout page={<BuyNow />} /> },
    { path: '/checkout', element: <Checkout /> },
    { path: '/privacy-policy', element: <PublicLayout page={<PrivacyPolicy />} /> },
    { path: '/terms-and-conditions', element: <PublicLayout page={<TermsandConditions />} /> },
    { path: '/refund-and-cancellation', element: <PublicLayout page={<RefundAndCancellation />} /> },
    { path: '/support', element: <PublicLayout page={<ContactUs />} /> },
    { path: '/login', element: <Login /> },
    { path: '/signup', element: <Signup /> },
    { path: '/forgotpassword', element: <ForgotPass /> },
  ];

  const adminRoutes = [
    { path: '/admin/dashboard', element: <AdminLayout page={<AdminDashboard />} /> },
    { path: '/admin/tickets', element: <AdminLayout page={<TicketsManagment />} /> },
    { path: '/admin/sold-tickets', element: <AdminLayout page={<SoldTickets />} /> },
    { path: '/admin/phases', element: <AdminLayout page={<Phases />} /> },
    { path: '/admin/users', element: <AdminLayout page={<UserTable />} /> },
    { path: '/admin/users/vieworder/:id', element: <AdminLayout page={<UserOrders />} /> },
    { path: '/admin/orders', element: <AdminLayout page={<OrderTable />} /> },
    { path: '/admin/orders/unprocessed', element: <AdminLayout page={<UnProcessedOrder />} /> },
    { path: '/admin/team-members', element: <AdminLayout page={<AddMember />} /> },
    { path: '/admin/orders/vieworder/:id', element: <OrderView /> },
    { path: '/admin/users/orders/:id', element: <AdminLayout page={<UserOrders />} /> },
    { path: '/admin/support', element: <AdminLayout page={<AdminSupport />} /> },
    { path: '/admin/cms', element: <AdminLayout page={<CMS />} /> },

    { path: '/admin/pre-event-orders', element: <AdminLayout page={<PreEventOrder />} /> },
    // { path: '/admin/pre-event-orders/create-order', element: <AdminLayout page={<CashOrder />} /> },

    { path: '/admin/on-going-event-orders', element: <AdminLayout page={<OnGoingEventOrders />} /> },
    // { path: '/admin/on-going-event-orders/create-order', element: <AdminLayout page={<CreateOnGoingEventOrders />} /> },

    { path: '/admin/students-orders', element: <AdminLayout page={<StudentOrders />} /> },
    // { path: '/admin/students-orders/create-order', element: <AdminLayout page={<CreateStudentOrders />} /> },
  ];

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.addEventListener("load", () => {
      setLoading(false);
    });
  }, []);

  const [isLogin, setIsLogin] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      setIsLogin(true)
    } else if (sessionStorage.getItem('token') != null) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
  }, [localStorage, sessionStorage])

  let token = getToken();


  // Under Maintenance
  const [isMaintenance, setisMaintenance] = useState(false)
  const fetchMaintenance = () => {
    axios.get(getWebMaintenance)
      .then((res) => {
        res.data.result.docs.map((ele) => {
          if (ele.flag === 1) {
            setisMaintenance(true)
          }
        })
      })
  }
  useEffect(() => {
    fetchMaintenance()
  }, [])

  // Check Admin Access
  var data = []
  var finalroutes = []

  if (localStorage.getItem('adminAccess')) data = JSON.parse(localStorage.getItem('adminAccess'))
  if (sessionStorage.getItem('adminAccess')) data = JSON.parse(sessionStorage.getItem('adminAccess'))

  if (data) {
    const accessData = adminRoutes.filter((route) => {
      return data?.some((accessItem) => accessItem.path === route.path);
    });
    accessData.map((ele) => {
      if (ele.path === "/admin/orders") {
        accessData.push({ path: '/admin/orders/vieworder/:id', element: <AdminLayout page={<OrderView />} /> })
      }
      if (ele.path === "/admin/users") {
        accessData.push({ path: '/admin/users/orders/:id', element: <AdminLayout page={<UserOrders />} /> })
        accessData.push({ path: '/admin/users/vieworder/:id', element: <AdminLayout page={<ViewTickets />} /> })
      }
      if (ele.path === "/admin/pre-event-orders") {
        accessData.push({ path: '/admin/pre-event-orders/create-order', element: <AdminLayout page={<CreatePreEventOrder />} /> })
        accessData.push({ path: '/admin/pre-event-orders/view-order/:id', element: <AdminLayout page={<ViewPreEventOrderDetails />} /> })
      }
      if (ele.path === "/admin/on-going-event-orders") {
        accessData.push({ path: '/admin/on-going-event-orders/create-order', element: <AdminLayout page={<CreateOnGoingEventOrders />} /> })
        accessData.push({ path: '/admin/on-going-event-orders/view-order/:id', element: <AdminLayout page={<ViewOnGoingEventOrderDetails />} /> })
      }
      if (ele.path === "/admin/students-orders") {
        accessData.push({ path: '/admin/students-orders/create-order', element: <AdminLayout page={<CreateStudentOrders />} /> })
      }
      if (ele.path === "/admin/dashboard") {
        accessData.push({ path: '/admin/dashboard/each-day-sale', element: <AdminLayout page={<EachDaySale />} /> })
        accessData.push({ path: '/admin/dashboard/tickets-scan-status', element: <AdminLayout page={<TicketsScanStatus />} /> })
      }
      if (ele.path === "/admin/team-members") {
        accessData.push({ path: '/admin/team-members/view-order/:id', element: <AdminLayout page={<MemberOrder />} /> })
        accessData.push({ path: '/admin/team-members/view-app-order/:id', element: <AdminLayout page={<AppMemberOrder />} /> })
      }
    })
    finalroutes = accessData;
  }

  return (
    <div className="App">
      {loading ? (
        <div className="preloader">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="routes stickyImgAdded">
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to="/admin" replace />} />
              {/* {
                isMaintenance ?
                  <>
                    <Route path="/" element={<Maintenance />} />
                    <Route path="*" element={<Navigate to={'/'} />} />
                  </>
                  :
                  routesConfig.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element} />
                  ))
              } */}

              {/* Admin */}
              {
                finalroutes && finalroutes.map((route, index) => (
                  <Route key={index} path={route.path} element={route.element} />
                ))
              }
              <Route path="*" element={<Error />} />
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route path="/admin/create-cash-tickets" element={<AdminLayout page={<CashOrder />} />} />
              <Route path="/admin/orders/unprocessed" element={<AdminLayout page={<UnProcessedOrder />} />} />
              <Route path="/admin/forgotpassword" element={<ForgotPassword />} />
              <Route path="/admin" element={<Navigate to={finalroutes.length > 0 ? finalroutes[0].path : '/admin/login'} />} />
            </Routes>
          </Router>
        </div>
      )}
    </div>
  );
}

export default App;