import React, { useEffect, useState } from 'react'
import "./Orders.css"
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'
import { FormControlLabel, IconButton } from '@material-ui/core';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { getAdminDetails, getUnprocessedorderslist, getdownloadCsv } from '../../Api'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import 'react-toastify/dist/ReactToastify.css';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { GoPersonAdd } from 'react-icons/go';
import { getAdminToken } from '../../Helper/Token';
import { BiExport } from 'react-icons/bi'
import { CiSearch } from 'react-icons/ci'
import Form from 'react-bootstrap/Form';
import { Pagination } from '@nextui-org/react'
import AddIcon from '@mui/icons-material/Add';
import FIlterIcon from '../../Images/Mandli/Filter.png'
import Popover from '@mui/material/Popover';
import { Scrollbar } from 'react-scrollbars-custom';
import { DateRangePicker } from 'react-date-range';
import { formatNumberWithCommas } from '../../Helper/validation';
import { Stack } from '@mui/material';
import { SuperBalls } from '@uiball/loaders';


const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
}

const secondmodel = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const style = {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    width: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
};

function UnProcessedOrder() {

    // Filter Popup
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [FilterActive, setFilterActive] = useState([]);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [state, setState] = useState([
        {
            startDate: new Date(),
            // endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    const handleDateState = (e) => {
        // setIsChanged(true)
        setState([e.selection])
    }



    const MatView = ({ index }) => {

        return (
            <FormControlLabel
                control={
                    <NavLink className="view_user_order" to={`/admin/orders/vieworder/${index._id}`}>
                        <IconButton aria-label="add an alarm" className='dyFlextIcon'>
                            <VisibilityOutlinedIcon />
                        </IconButton>
                    </NavLink>
                }
            />
        );
    };


    // Get User Details
    const [adminDetails, setAdminDetails] = useState({});
    const fetchAdminDetails = () => {
        getAdminToken();
        axios.patch(getAdminDetails)
            .then((res) => {
                setAdminDetails(res.data.admin)
            })
    }
    useEffect(() => {
        fetchAdminDetails();
    }, [])

    // Get Order List
    const [rows, setRows] = useState([]);
    const [stats, setStates] = useState({
        totalTickets: 0,
        total24HSale: 0,
        totalSale: 0
    })

    // Pagination
    const [pageState, setPageState] = useState({
        total: 0,
        totalPages: 0,
        page: 1,
        pageSize: 10
    })
    const handlePageChange = (selectedPage) => {
        setPageState((prevState) => ({ ...prevState, page: selectedPage }));
    };
    const ResultInfo = () => {
        const start = (pageState.page - 1) * pageState.pageSize + 1;
        const end = Math.min(start + pageState.pageSize - 1, pageState.total);

        return (
            <div className="result-info">
                Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Orders
            </div>
        );
    };
    const handlePageSizeChange = (newPageSize) => {
        setPageState((prevState) => ({
            ...prevState,
            page: 1,
            pageSize: newPageSize,
        }));
    };

    // Search
    const [search, setSearch] = useState("")
    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    // Print Data
    const columns = [
        { field: 'id', headerName: 'Id', width: 40 },
        { field: 'UserName', headerName: 'User Name', width: 130, },
        { field: 'UserEmail', headerName: 'Email', width: 130, },
        { field: 'ISTDate', headerName: 'Date', width: 200, },
        { field: 'totalTickets', headerName: 'Total Tickets', width: 100, },
        { field: 'PurchasedTickets', headerName: 'Total Visitors', width: 100, },
        // { field: 'PaymentType', headerName: 'Payment Type', width: 110, },
        { field: 'OrderTotal', headerName: 'Total', width: 80, },
        {
            field: "actions", headerName: "Action", sortable: false, width: 75,
            renderCell: (prevents) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <MatView index={prevents.row} />
                    </div>
                );
            }
        }
    ];

    const fetchOrderList = () => {
        getAdminToken();
        axios.get(`${getUnprocessedorderslist}?search=${search}&page=${pageState.page}&limit=${pageState.pageSize}`)
            .then((res) => {
                res.data.result.docs.map((ele, i) => {
                    const json = res.data.result;
                    setPageState((prevState) => ({ ...prevState, total: json.totalDocs, totalPages: json.totalPages }));

                    ele.srno = (res.data.result.page * 10) + (i + 1) - 10
                    ele.id = i + 1;

                    ele.UserName = ele.UserInfo?.name
                    ele.UserEmail = ele.UserInfo?.email

                    const timestamp = ele.createdAt;
                    const date = new Date(timestamp);
                    ele.ISTDate = date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" });

                    if (ele.PaymentType.PaymenttypeNumber == 1) {
                        ele.PaymentType = "Online"
                    } else if (ele.PaymentType == 2) {
                        ele.PaymentType = "Cash"
                    }

                    ele.totalTickets = ele?.TicketInfo?.length
                });
                setRows(res.data.result.docs)
                setStates({
                    ...stats,
                    totalTickets: res.data.result.totalDocs,
                    totalSale: res.data.result.GrandTotal,
                    total24HSale: res.data.result.last24HourSaleAmount
                })
            })
    }
    useEffect(() => {
        fetchOrderList();
    }, [search, pageState.page, pageState.pageSize])

    // Download CSV List
    const backendUrl = 'http://';
    const [Export, setExport] = useState([])

    const HandleDownloadCsv = async (Export) => {
        getAdminToken();
        axios.get(getdownloadCsv).then((res) => {
            toast.success(res.data.message, toastStyle);
            setExport(res.data.result)
            const link = document.createElement('a');
            link.href = `${backendUrl}${res.data.result}`; // Set the PDF URL
            link.download = `${backendUrl}${res.data.result}`;
            link.setAttribute('target', "_blank") // Set the desired filename for the downloaded file
            link.setAttribute('download', 'Mandli.pdf')
            link.setAttribute('rel', "noreferrer")
            document.body.appendChild(link);
            link.click();
        }).catch((err) => {
            toast.error(err.response.data.message, toastStyle);
        })
    }

    return (
        <div className='userdashboard'>
            <div className="dashboard-right-header">
                <div className="left-text">
                    <h4>Unprocessed Orders </h4>
                </div>
            </div>

            <div className="userdash-row three mb-4">
                <div className="dashboard-stats-box ">

                    <div className="single-feature-box sfb01">
                        <div className="single-feature-icon">
                            <LocalActivityIcon />
                        </div>
                        <div className="single-feature-data">
                            <h6>{stats.totalTickets || 0}</h6>
                            <p>Total Unprocessed Orders</p>
                        </div>
                    </div>

                </div>
                <div className="dashboard-stats-box">
                    <div className="single-feature-box bg-danger-50 ">
                        <div className="single-feature-icon">
                            <CurrencyRupeeIcon />
                        </div>
                        <div className="single-feature-data">
                            <h6>{formatNumberWithCommas(stats.totalSale) || 0}</h6>
                            <p>Total Unprocessed Sale</p>
                        </div>

                    </div>
                </div>
                {/* <div className="dashboard-stats-box">
                    <div className="single-feature-box ">
                        <div className="single-feature-icon">
                            <CurrencyRupeeIcon />
                        </div>
                        <div className="single-feature-data ">
                            <h6>{formatNumberWithCommas(stats.total24HSale)}</h6>
                            <p>Last 24 Hours Sale</p>
                        </div>

                    </div>
                </div> */}
            </div>

            <div className='filter-Search userList sold_tickets'>
                <div className="search-box form-item position-relative x_sold_tickets">
                    <Form.Control type="text" placeholder="Search.." name="search" className="search-input w-100" onChange={(e) => handleSearch(e)} />
                    <CiSearch />
                </div>
                <div className='filter-block'>
                    <div className='qutrowftr08s'>
                        <div className='create-block'>
                            {/* <Button className='usdercmntbtn common-btn' ><GoPersonAdd />Add Cash Tickets</Button> */}
                            <img src={FIlterIcon} alt="" aria-describedby={id} variant="contained" onClick={handleClick} />


                            <div className='fillter' style={{ position: 'relative' }}>
                                <Popover id={id} className='popober_model_Css' open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}>

                                    <div className="section_p_20">
                                        <div className="drwdownMenus">
                                            <Button className='rst-Btn-Lnk' autoFocus  >
                                                Reset
                                            </Button>
                                            <Button className='aply-Btn-Lnk' autoFocus>
                                                Apply
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='HL'></div>

                                    <div className='filter_body'>
                                        <div className="short_buy_buttons">
                                            <div className="colone">
                                                <h1>Sort by</h1>
                                                <Form.Select aria-label="Default select example">
                                                    <option disabled>Newest First</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>
                                            <div className="colone">
                                                <h1>Sort by User</h1>
                                                <Form.Select aria-label="Default select example">
                                                    <option disabled>User</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>
                                        </div>

                                        <div className="shot_buy_button"></div>

                                    </div>

                                    <div className="datePiker">
                                        <div >
                                            <DateRangePicker
                                                onChange={(e) => handleDateState(e)}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={1}
                                                ranges={state}
                                                direction="horizontal"
                                            />
                                        </div>
                                    </div>
                                </Popover>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='filter-block'>
                    <div className='qutrowftr08s'>
                        <div className='create-block export_sheet'>
                            <Button className='usdercmntbtn common-btn' onClick={HandleDownloadCsv} ><BiExport />EXPORT</Button>
                        </div>
                    </div>
                </div>

            </div>


            <div className='datagridtableusqot00qat'>
                <Box sx={{ width: '100%' }}>
                    <DataGrid
                        autoHeight {...rows}
                        rows={rows}
                        columns={columns}
                        pageSize={pageState.pageSize}
                        disableSelectionOnClick
                        pagination={false}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    <SuperBalls size={45} speed={1.4} color="var(--primary)" />
                                </Stack>
                            )
                        }}
                    />
                </Box>
            </div>
            <div className='pagination-block'>
                <ResultInfo />
                <div className='customPagination'>
                    <div className="page-size-select">
                        Page Size:{" "}
                        <select
                            value={pageState.pageSize}
                            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                    <Pagination
                        loop
                        color="success"
                        total={pageState.totalPages}
                        initialPage={1}
                        boundaries={1}
                        siblings={0}
                        onChange={(page) => handlePageChange(page)}
                        currentPage={pageState.page}
                    />
                </div>
            </div>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default UnProcessedOrder


